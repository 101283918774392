<script>
import { mask } from 'vue-the-mask'
import { mapActions } from 'vuex'

export default {
  name: 'BatchDetails',
  directives: { mask },
  components: {
    Modal: () => import('@/components/general/Modal'),
    Action: () => import('@/components/general/Action'),
    Datatable: () => import('@/components/general/Datatable'),
    Pagination: () => import('@/components/general/Pagination')
  },
  data () {
    return {
      batchDetailsList: [],
      type: ''
    }
  },
  methods: {
    ...mapActions([
      'attemptGetBatchDetailsList',
      'setFetching',
      'setFeedback'
    ]),
    getBatchDetails (batchId) {
      this.setFetching(true)
      this.attemptGetBatchDetailsList(batchId).then((response) => {
        if (response) {
          this.batchDetailsList = response.data
        }
      }).finally(() => {
        this.setFetching(false)
      })
    },
    dateFormat (datetime) {
      if (datetime === '' || datetime === undefined || datetime === null) {
        return datetime
      }
      const date = new Date(datetime)
      return Intl.DateTimeFormat('pt-BR').format(date)
    },
    decodeData (encodedData) {
      return JSON.parse(encodedData)
    },
    leave () {
      this.$router.push({ name: 'batch.list' })
    }
  },
  created () {
    this.type = this.$route.params.type
    this.getBatchDetails(this.$route.params.id)
  }
}
</script>
<template>
  <modal
    :active="true"
    :cancel-label="$t('global:back')"
    @close="leave"
  >
    <div class="modal-form modal-add-user">
      <h2 class="modal-title">
        {{ $t('batch.details.title') }}
      </h2>
      <span class="modal-subtitle">{{ $t('user.management.index:modal.description.batch.list') }}</span>
      <div
        v-if="batchDetailsList.length > 0"
        class="center users-table"
      >
        <datatable
          :items="batchDetailsList"
          dark
        >
          <template slot="thead">
            <tr>
              <th class="th">
                {{ $t('batch.details:datatable.header.1') }}
              </th>
              <th class="th">
                {{ $t('batch.details:datatable.header.2') }}
              </th>
              <th
                v-if="type === 'batch/enrollment'"
                class="th"
              >
                {{ $t('batch.details:datatable.header.3') }}
              </th>
              <th class="th">
                {{ $t('batch.details:datatable.header.4') }}
              </th>
            </tr>
          </template>
          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr>
              <td class="td">
                <span class="td-text bolder td-block">{{ decodeData(props.item.data).username ? decodeData(props.item.data).username : decodeData(props.item.data).cpf }}</span>
              </td>
              <td class="td">
                <span class="td-text">{{ $t('batch.details.message:' + props.item.message) }}</span>
              </td>
              <td
                v-if="type === 'batch/enrollment'"
                class="td"
              >
                <span class="td-text">{{ decodeData(props.item.data).sessionId }}</span>
              </td>
              <td class="td">
                <span class="td-text">{{ dateFormat(props.item.createdAt) }}</span>
              </td>
            </tr>
          </template>
        </datatable>
      </div>
      <div
        v-if="batchDetailsList.length === 0"
        class="batch-data-message"
      >
        <h3>Não há dados para serem exibidos.</h3>
      </div>
    </div>
  </modal>
</template>

<style>
.batch-data-message {
  height: 10em;
  position: relative
}

.batch-data-message h3 {
  color: #E0E0E0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 -50% 0 0;
  transform: translate(-50%, -50%)
}
</style>
